import React from "react";

export default ({ color }) => (
  <svg width="68" height="51" viewBox="0 0 68 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.9999 0L0.774902 28.03H9.8659L33.9999 7.671L58.1339 28.03H67.2249L33.9999 0Z" fill="black" />
    <path d="M14.7019 25.909V50.407H53.2989V25.909L33.9999 9.628L14.7019 25.909ZM39.8789 27.679C39.8789 30.926 37.2469 33.558 33.9999 33.558C30.7539 33.558 28.1209 30.926 28.1209 27.679C28.1209 24.432 30.7539 21.796 33.9999 21.796C37.2469 21.796 39.8789 24.433 39.8789 27.679Z" fill="black" />
    <path d="M25 31C25 32.6569 23.6569 34 22 34C20.3431 34 19 32.6569 19 31C19 29.3431 20.3431 28 22 28C23.6569 28 25 29.3431 25 31Z" fill="white" />
    <path d="M25 38C25 39.6569 23.6569 41 22 41C20.3431 41 19 39.6569 19 38C19 36.3431 20.3431 35 22 35C23.6569 35 25 36.3431 25 38Z" fill="white" />
    <path d="M37 38C37 39.6569 35.6569 41 34 41C32.3431 41 31 39.6569 31 38C31 36.3431 32.3431 35 34 35C35.6569 35 37 36.3431 37 38Z" fill="white" />
    <path d="M37 45C37 46.6569 35.6569 48 34 48C32.3431 48 31 46.6569 31 45C31 43.3431 32.3431 42 34 42C35.6569 42 37 43.3431 37 45Z" fill="white" />
    <path d="M25 45C25 46.6569 23.6569 48 22 48C20.3431 48 19 46.6569 19 45C19 43.3431 20.3431 42 22 42C23.6569 42 25 43.3431 25 45Z" fill="white" />
    <path d="M49 31C49 32.6569 47.6569 34 46 34C44.3431 34 43 32.6569 43 31C43 29.3431 44.3431 28 46 28C47.6569 28 49 29.3431 49 31Z" fill="white" />
    <path d="M49 38C49 39.6569 47.6569 41 46 41C44.3431 41 43 39.6569 43 38C43 36.3431 44.3431 35 46 35C47.6569 35 49 36.3431 49 38Z" fill="white" />
    <path d="M49 45C49 46.6569 47.6569 48 46 48C44.3431 48 43 46.6569 43 45C43 43.3431 44.3431 42 46 42C47.6569 42 49 43.3431 49 45Z" fill="white" />
  </svg>




);

